import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { TYPE } from 'theme'
import Logo from '../../assets/images/logo.png'
import TelegramLogo from '../../assets/svg/telegram-logo.svg'
import TwitterLogo from '../../assets/svg/twitter-logo.svg'
import MLogo from '../../assets/svg/m-logo.svg'
import IntagramLogo from '../../assets/svg/instagram-logo.svg'
import YoutubeLogo from '../../assets/svg/youtube-logo.svg'
import FacebookLogo from '../../assets/svg/facebook-logo.svg'
import LinkedinLogo from '../../assets/svg/linkedin-logo.svg'

const FooterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 1024px) {
    padding-left: 30px;
    padding-right: 30px;
  }
`

const FooterLinksContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0px auto 40px auto;

  @media screen and (max-width: 768px) {
    gap: 20px;
    justify-content: center;
  }
`

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 150px;

  @media screen and (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 1rem;
  }
`

const ColumnHeader = styled.h3`
  color: #1f1f1f;
  font-weight: 400;
  font-size: 21px;
  line-height: 26px;
  font-family: 'Inter';
  margin-bottom: 30px;
`

const CLink = styled(Link)`
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #6e727d;
  text-decoration: none;
  margin-bottom: 12px;

  &:hover {
    color: #000;
  }
`

const Icon = styled.div`
  transition: transform 0.3s ease;
  margin-bottom: 30px;

  img {
    max-width: 300px;
    width: 100%;
  }
`
const SocialIcons = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 80px;
  gap: 40px;
`

const footerLinks: any = {
  Concept: [
    {
      id: 1,
      label: 'Intro to AME Chain',
      to: '#'
    },
    {
      id: 1,
      label: 'AME Chain',
      to: '#'
    },
    {
      id: 1,
      label: 'QRNG',
      to: '#'
    },
    {
      id: 1,
      label: 'AME',
      to: '#'
    },
    {
      id: 1,
      label: 'Tokenomics',
      to: '#'
    },
    {
      id: 1,
      label: 'Roadmap',
      to: '#'
    },
    {
      id: 1,
      label: 'Stake AME',
      to: '#'
    }
  ],
  Developers: [
    {
      id: 1,
      label: 'Validator Node',
      to: '#'
    },
    {
      id: 1,
      label: 'Non Validator Node',
      to: '#'
    },
    {
      id: 1,
      label: 'Node List',
      to: '#'
    },
    {
      id: 1,
      label: 'Github',
      to: '#'
    },
    {
      id: 1,
      label: 'EVM Documentation',
      to: '#'
    },
    {
      id: 1,
      label: 'Quantum Mainnet',
      to: '#'
    },
    {
      id: 1,
      label: 'Quantum Testnet',
      to: '#'
    }
  ],
  Tools: [
    {
      id: 1,
      label: 'AME Wallet',
      to: '#'
    },
    {
      id: 1,
      label: 'Mainnet Explorer',
      to: '#'
    },
    {
      id: 1,
      label: 'Testnet Explorer',
      to: '#'
    },
    {
      id: 1,
      label: 'Guides',
      to: '#'
    }
  ],
  Enterprise: [
    {
      id: 1,
      label: 'Enterprise',
      to: '#'
    },
    {
      id: 1,
      label: 'Solutions',
      to: '#'
    }
  ],
  Ecosystem: [
    {
      id: 1,
      label: 'Whitepaper',
      to: '#'
    },
    {
      id: 1,
      label: "FAQ's",
      to: '#'
    },
    {
      id: 1,
      label: 'Community',
      to: '#'
    },
    {
      id: 1,
      label: 'Contact Us',
      to: '#'
    }
  ]
}

const Footer = () => {
  return (
    <FooterContainer>
      <Icon>
        <img src={Logo} alt="logo" />
      </Icon>
      <TYPE.subHeader
        color="#6E727D"
        fontSize="17px"
        lineHeight="31px"
        maxWidth="784px"
        textAlign="center"
        marginBottom="30px"
      >
        AME Chain is the world’s first and only Quantum secured decentralized digital asset ledger that is EVM
        compatible with high performance and scalability.
      </TYPE.subHeader>
      <SocialIcons>
        <Link to="#">
          <img src={TelegramLogo} />
        </Link>
        <Link to="#">
          <img src={TwitterLogo} />
        </Link>
        <Link to="#">
          <img src={MLogo} />
        </Link>
        <Link to="#">
          <img src={IntagramLogo} />
        </Link>
        <Link to="#">
          <img src={YoutubeLogo} />
        </Link>
        <Link to="#">
          <img src={FacebookLogo} />
        </Link>
        <Link to="#">
          <img src={LinkedinLogo} />
        </Link>
      </SocialIcons>
      <FooterLinksContainer>
        {Object.keys(footerLinks).map(columnHeading => (
          <ColumnContainer key={columnHeading}>
            <ColumnHeader>{columnHeading}</ColumnHeader>
            {footerLinks[columnHeading].map((link: any) => (
              <CLink key={link.id} to={link.to}>
                {link.label}
              </CLink>
            ))}
          </ColumnContainer>
        ))}
      </FooterLinksContainer>
    </FooterContainer>
  )
}

export default Footer
