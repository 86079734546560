import React from 'react'
import styled from 'styled-components'
import { transparentize } from 'polished'

// align-items: center;
//
//
//
//
//
//
// display: flex;
// flex-direction: column;
// height: auto;
// left: 506px;
//
// position: absolute;
// top: 152px;
// width: 500px;

export const BodyWrapper = styled.div`
  position: relative;
  max-width: 500px;
  width: 100%;
  background-color: ${({ theme }) => transparentize(0.7, theme.black)};
  /* padding: 1rem; */

  // box-shadow: 0px 13px 64px #00000040;
  // backdrop-filter: blur(4px) brightness(100%);
  border-radius: 24px;
  mix-blend-mode: normal;
  // border-color: #32325e !important;
  // border: 1px solid;
  box-shadow: inset 0px 0px 34.0148px rgba(255, 255, 255, 0.05), inset 0px 2.26765px 2.26765px rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(36.3611px);
  background: rgba(255, 255, 255, 0.3);
  border: 1px solid #f46228;
`

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ children }: { children: React.ReactNode }) {
  return <BodyWrapper>{children}</BodyWrapper>
}
